<template>
  <div id="flipCard" class="flipper" :class="ura">
    <img v-lazy="cardImage" :class="omotecss" alt="" />
    <img :src="uraImage" :class="uracss" alt="" />
  </div>
</template>

<script>
import anime from "animejs";

const regex = /[^0-9|.]/g;

const cardArt = [
  "mogu",
  "apple",
  "banana",
  "chicken",
  "cicada",
  "corn",
  "cucumber",
  "dragonfly",
  "duck",
  "elephant",
  "flamingo",
  "fox",
  "frog",
  "giraffe",
  "gorilla",
  "horse",
  "jellyfish",
  "kangaroo",
  "lettuce",
  "lion",
  "mouse",
  "octopus",
  "penguin",
  "pig",
  "rabbit",
  "shark",
  "squirrel",
  "tomato",
  "watermelon",
  "whale",
  "zebra",
];
export default {
  name: "card",
  data() {
    return {
      backGroundImage: "./img/card-back/SMC_Card_tra.png",
      backGround_uraImage: "./img/cardImages/00_mogu.png",
      ura: "",
      cardDeg: 0,
      fliping: false,
      cardId: 0,
      ccolor: "red",
      omotecss: "red-omote",
      uracss: "off",
    };
  },
  mounted() {},
  computed: {
    cardImage() {
      return this.backGroundImage;
    },
    uraImage() {
      return this.backGround_uraImage;
    },
  },
  methods: {
    generateImageUriPathById(id) {
      return (
        "./img/cardImages/" + ("00" + id).slice(-2) + "_" + cardArt[id] + ".png"
      );
    },
    onLoad() {
      this.uracss = "";
      this.omotecss = "off";
    },

    setImage() {
      this.cardId = this.$store.state.qnum;
      this.backGround_uraImage = this.generateImageUriPathById(this.cardId);
    },

    flipCard(newColor) {
      this.ccolor = newColor;
      let tmpcolor = this.omotecss;
      let flipped = false;
      if (this.fliping) return;
      this.fliping = true;
      anime({
        targets: ".flipper",
        rotateY: [0, 360],
        duration: 500,
        easing: "easeInOutQuad",
        update: (anim) => {
          const result = anim.animations[0].currentValue.replace(regex, "");
          this.cardDeg = result;
          if (90 < Math.abs(180 - (Math.abs(result) % 360))) {
            this.omotecss = flipped ? newColor + "-omote" : tmpcolor;
            this.ura = "";
            this.uracss = "off";
          } else {
            flipped = true;

            this.ura = "ura " + newColor;
            this.uracss = "";
            this.omotecss = "off";
          }
        },
        complete: () => {
          this.fliping = false;
        },
      });
    },
    open(newid) {
      this.cardId = newid;

      if (this.fliping) return;
      this.fliping = true;
      anime({
        targets: ".flipper",
        rotateY: [0, 180],
        duration: 500,
        easing: "easeInOutQuad",
        update: (anim) => {
          const result = anim.animations[0].currentValue.replace(regex, "");
          this.cardDeg = result;
          if (90 < Math.abs(180 - (Math.abs(result) % 360))) {
            this.ura = "";
            this.uracss = "off";
            this.omotecss = this.ccolor + "-omote";
          } else {
            this.ura = "ura " + this.ccolor;
            this.uracss = "";
            this.omotecss = "off";
          }
        },
        complete: () => {
          this.fliping = false;
        },
      });
    },
    setColor() {
      switch (this.$store.state.level) {
        case 0:
          this.ccolor = "red";
          break;
        case 1:
          this.ccolor = "orange";
          break;
        case 2:
          this.ccolor = "yellow";
          break;
        case 3:
          this.ccolor = "green";
          break;
      }
    },

    init() {
      this.setColor();
      this.omotecss = this.ccolor + "-omote";
      this.setImage();
      this.ura = "";
      this.uracss = "off";
      anime({
        targets: ".flipper",
        rotateY: [0, 0],
        duration: 0,
        easing: "easeInOutQuad",
      });
    },
    close() {
      // let tmpImage = cardImages[this.ccolor];
      if (this.fliping) return;
      this.fliping = true;
      anime({
        targets: ".flipper",
        rotateY: [180, 0],
        duration: 500,
        easing: "easeInOutQuad",
        update: (anim) => {
          const result = anim.animations[0].currentValue.replace(regex, "");
          this.cardDeg = result;
          if (90 < Math.abs(180 - (Math.abs(result) % 360))) {
            this.ura = "";
            this.uracss = "off";
            this.omotecss = this.ccolor + "-omote";
          } else {
            this.ura = "ura " + this.ccolor;
            this.uracss = "";
            this.omotecss = "off";
          }
        },
        complete: () => {
          this.fliping = false;
          this.setImage();
        },
      });
    },
    changeCard() {},
  },
};
</script>

<style scoped lang="scss">
#flipCard {
  box-sizing: border-box;
  width: min(600px, 100% - 50px);
  height: fit-content;
  margin: auto;
  box-shadow: 0px 5px 0px 0px #cacaca;
  border-radius: 25px;
  img {
    margin: 0 auto;
    width: 100%;
    height: auto;
  }

}

.red {
  background-color: white;
  border-radius: 15px;
  border: 15px solid #e7231a;
}

.orange {
  background-color: white;
  border-radius: 15px;
  border: 15px solid #f49d1c;
}
.yellow {
  background-color: white;
  border-radius: 15px;
  border: 15px solid #ffe102;
}
.green {
  background-color: white;
  border-radius: 15px;
  border: 15px solid #48b232;
}

.red-omote {
  background-color: #e7231a;
  border-radius: 25px;
  @media all and (max-width: 900px) {
    border-radius: 15px;
  }
}

.orange-omote {
  background-color: #f49d1c;
  border-radius: 25px;
  @media all and (max-width: 900px) {
    border-radius: 15px;
  }
}
.yellow-omote {
  background-color: #ffe102;
  border-radius: 25px;
  @media all and (max-width: 900px) {
    border-radius: 15px;
  }
}
.green-omote {
  background-color: #48b232;
  border-radius: 25px;
  @media all and (max-width: 900px) {
    border-radius: 15px;
  }
}

.ura img {
  width: calc(100% - 18px) !important ;
  height: auto;
  transform: scaleX(-1);
}

.off {
  display: none;
}
</style>
