<template>
  <a class="btn btn-custom01" :class="buttonColor" @click="$emit('btnClick')">
    <span class="btn-custom01-front" :class="color_sub">{{ message }}</span>
  </a>
</template>

<script>
export default {
  name: "coolButton",
  props: {
    message: String,
    color: String,
    setlevel: Number,
    short: Boolean,
  },
  data() {
    return {};
  },
  computed: {
    color_sub() {
      return this.color + "_sub";
    },
    buttonColor() {
      return "fusen " + this.color + (this.short ? " short" : "");
    },
    level() {
      return this.$store.state.level;
    },
  },
  methods: {
    changeLevel() {
      this.$emit("flip", this.color);
      this.$store.commit("countUp", this.setlevel);
    },
  },
};
</script>

<style scoped lang="scss">
*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

.btn,
a.btn,
button.btn {
  width: 200px;
  margin: 20px 10px;

  @media all and (max-width: 900px) {
    width: 40%;
    min-width: 140px;

    margin: 20px 10px;
  }
  font-weight: 100;
  font-size: 1.6rem;
  // line-height: 1.5;
  position: relative;
  display: inline-block;
  // padding: 1rem 4rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  text-align: center;
  vertical-align: middle;
  text-decoration: none;
  letter-spacing: 0.1em;
  color: #212529;
  border-radius: 0.5rem;
}

a.btn-custom01 {
  margin-bottom: 0.5rem;
  padding: 0;
  border-radius: 0.75rem;
}

a.btn-custom01:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-transform: translate3d(0, 0.75rem, -1rem);
  transform: translate3d(0, 0.75rem, -1rem);

  border: 2px solid #000;
  border-radius: inherit;

  // -webkit-box-shadow: 0 0.6rem 0 0 rgba(0, 0, 0, 0.2);
  // box-shadow: 0 0.6rem 0 0 rgba(0, 0, 0, 0.2);
}

.btn-custom01-front {
  position: relative;
  display: block;
  padding: 0.7rem 0 0.2rem;
  font-family: "dmfont";
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border: 2px solid #000;
  border-radius: inherit;
}

// a.btn-custom01:hover {
//   -webkit-transform: translate(0, 0.25rem);
//   transform: translate(0, 0.25rem);
//   background: #fff100;
// }

// a.btn-custom01:hover:before {
//   -webkit-transform: translate3d(0, 0.5rem, -1rem);
//   transform: translate3d(0, 0.5rem, -1rem);
//   -webkit-box-shadow: 0 0.35rem 0 0 rgba(0, 0, 0, 0.2);
//   box-shadow: 0 0.35rem 0 0 rgba(0, 0, 0, 0.2);
// }

a.btn-custom01:active {
  -webkit-transform: translate(0rem, 0.75rem);
  transform: translate(0rem, 0.75rem);
}

a.btn-custom01:active:before {
  -webkit-transform: translate3d(0, 0, -1rem);
  transform: translate3d(0, 0, -1rem);
}

.red::before {
  background: #d44872;
}

.red_sub {
  background: #ff6492;
}
.orange::before {
  background: #db9419;
}
.orange_sub {
  background: #ffa200;
}

.yellow::before {
  background: #ccc100;
}
.yellow_sub {
  background: #fff100;
}

.green::before {
  background: #7fc900;
}

.green_sub {
  background: #a2ff00;
}

.start::before {
  background: #e4e4e4;
}

.start_sub {
  background: #e9f8ff;
}

.short {
  width: 80px !important;
  z-index: 20;
  @media all and (max-width: 900px) {
    width: 80px !important;
  }
}
</style>
