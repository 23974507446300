import Vue from "vue";
import App from "./App.vue";
import "normalize.css";
import "./assets/scss/font.scss";
import store from "../store";
import VueLazyload from "vue-lazyload";

const loadimage = require("./assets/img/00_mogu.png");
const errorimage = require("./assets/img/00_mogu.png");

Vue.config.productionTip = false;
Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: errorimage,
  loading: loadimage,
  attempt: 1,
});

new Vue({
  store,
  render: (h) => h(App),
}).$mount("#app");
