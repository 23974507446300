<template>
  <div id="app">
    <Header></Header>
    <Game></Game>
  </div>
</template>

<script>
import Game from "./components/game.vue";
import Header from "./components/header.vue";
export default {
  name: "App",
  components: {
    Game,
    Header,
  },
};
</script>

<style lang="scss">
body {
  overflow: hidden;
}
html,
body {
  height: 100%;
}
#app {
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0;
  box-sizing: border-box;
  overflow: hidden;
}
</style>
