import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    level: 0,
    qnum: 0,
  },
  mutations: {
    countUp(state, newLevel) {
      this.state.level = newLevel;
    },
  },
});
