<template>
  <div id="mainWindow">
    <mainCard
      style="position: absolute"
      ref="main"
      @scc="changeScene"
      :class="main"
    ></mainCard>
    <gameCard
      style="position: absolute"
      ref="game"
      :class="game"
      @backTitle="changeScene_reverse"
    ></gameCard>
  </div>
</template>

<script>
import mainCard from "./card/maincard.vue";
import gameCard from "./card/gamecard.vue";
export default {
  name: "game",
  data() {
    return {
      main: "",
      game: "off",
    };
  },
  components: {
    mainCard,
    gameCard,
  },
  methods: {
    changeScene() {
      this.main = "off";
      this.game = "";
      this.$refs.main.fadeOut();
      this.$refs.game.fadeIn();
    },
    changeScene_reverse() {
      this.main = "";
      this.game = "off";
      this.$refs.main.fadeIn();
      this.$refs.game.fadeOut();
    },
  },
};
</script>

<style lang="scss">
#mainWindow {
  width: 100%;
  height: calc(100% - 70px);
  position: relative;
  margin-top: 70px;
  min-height: 90vh;
  background-image: url("../../public/img/background-images/Mogu_grass_field_210112a.jpg");
  background-position: center center;
  background-size: cover;
  display: grid !important;
  place-items: center;
  @media all and (max-width: 900px) {
    display: block;
  }
}

.off {
  pointer-events: none;
}
</style>
