<template>
  <div id="gamecard" style="overflow: hidden">
    <div class="inner" style="overflow: hidden">
      <rButton
        message="X"
        :short="true"
        color="green"
        style="position: absolute; top: 10px; left: 10px"
        @btnClick="backTitle()"
      ></rButton>
      <div class="remain">
        <span> {{ 30 - remain.length }}/</span>{{ 30 }}
      </div>
      <div class="contentWrapper">
        <card ref="cardTarget" class="theCard" style=""> </card>
        <VueJsProgress
          title=""
          :percentage="prog"
          bg="pinkglamour"
          :delay="500"
          :striped="true"
          :animation="true"
          class="prog"
        ></VueJsProgress>
      </div>
      <div
        class="players"
        style="width: 100%; position: absolute"
        :class="play"
      >
        <rButton :message="msg" color="yellow" @btnClick="stop()"></rButton>
        <rButton
          message="REPLAY"
          color="yellow"
          @btnClick="restart()"
        ></rButton>
        <rButton message="ANSWER" color="red" @btnClick="answer()"></rButton>
      </div>
      <div
        class="nextButton"
        style="width: 100%; position: absolute"
        :class="nextb"
      >
        <rButton :message="btnm" color="red" @btnClick="goNext()"></rButton>
      </div>
    </div>
  </div>
</template>

<script>
import card from "../card/card.vue";
import anime from "animejs";
import VueJsProgress from "../ui/prog.vue";
import rButton from "../ui/randomButton.vue";
// import tone from "tone";

const cardArt = [
  "mogu",
  "apple",
  "banana",
  "chicken",
  "cicada",
  "corn",
  "cucumber",
  "dragonfly",
  "duck",
  "elephant",
  "flamingo",
  "fox",
  "frog",
  "giraffe",
  "gorilla",
  "horse",
  "jellyfish",
  "kangaroo",
  "lettuce",
  "lion",
  "mouse",
  "octopus",
  "penguin",
  "pig",
  "rabbit",
  "shark",
  "squirrel",
  "tomato",
  "watermelon",
  "whale",
  "zebra",
];
let audioElem = new Audio();
export default {
  name: "maincard",
  data() {
    return {
      remain: [],
      isPlaying: false,
      isAnswerMpde: false,
      qnum: 0,
      totalTime: 0,
      pre: 0,
      nums: 10,
      play: "",
      nextb: "off",
      buttonMsg: ">> NEXT >>",
    };
  },
  components: {
    card,
    VueJsProgress,
    rButton,
  },
  computed: {
    msg() {
      return this.isPlaying ? "STOP" : "RESUME";
    },
    prog() {
      return this.pre;
    },
    btnm() {
      return this.remain.length < 1 ? "END" : ">> NEXT >>";
    },
  },
  methods: {
    emitter() {},
    filpCard(newColor) {
      this.$refs.cardTarget.flipCard(newColor);
    },

    backTitle() {
      this.isAnswerMpde = false;
      audioElem.pause();
      this.$emit("backTitle");
    },

    generateSoundUriPathById(level, id) {
      let prefix = "";
      let aferfix = "";
      switch (level) {
        case 0:
          prefix = "answers/";
          aferfix = "_ans";
          break;
        case 1:
          prefix = "L1_";
          break;
        case 2:
          prefix = "L2_";
          break;
        case 3:
          prefix = "L3_";
          break;
        case 4:
          return this.generateSoundUriPathById(
            Math.floor(Math.random() * 3 + 1),
            id
          );
      }
      let str =
        "./sounds/" +
        prefix +
        ("00" + id).slice(-2) +
        "_" +
        cardArt[id] +
        aferfix +
        ".mp3";
      return str;
    },

    next() {},

    fadeIn() {
      this.init();

      anime({
        targets: "#gamecard",
        translateY: [-60, 0],
        opacity: [0, 1],
        // rotateY: [130, 0],
        duration: 500,
        easing: "easeOutExpo",
        delay: 200,
      });
      this.isAnswerMpde = false;
      this.nextMode();

      audioElem.currentTime = 0;
      this.isPlaying = true;
      audioElem.src = this.generateSoundUriPathById(
        this.$store.state.level + 1,
        this.getRandomQuestion()
      );
      this.$refs.cardTarget.init();
      audioElem.play();
      this.isPlaying = true;
    },
    fadeOut() {
      this.$refs.cardTarget.init();
      anime({
        targets: "#gamecard",
        translateY: [0, 60],
        opacity: [1, 0],
        // rotateY: [130, 0],
        duration: 500,
        easing: "easeOutExpo",
        delay: 200,
      });
    },

    stop() {
      if (audioElem.paused) {
        audioElem.play();
        this.isPlaying = true;
      } else {
        audioElem.pause();
        this.isPlaying = false;
      }
    },
    restart() {
      audioElem.currentTime = 0;
      audioElem.play();
      this.isPlaying = true;
    },

    init() {
      this.isAnswerMpde = false;
      this.remain = [];
      for (let i = 1; i < 31; i++) {
        this.remain.push(i);
      }
    },

    answer() {
      this.isAnswerMpde = true;
      this.playMode();
      audioElem.src = this.generateSoundUriPathById(0, this.qnum);
      audioElem.currentTime = 0;
      audioElem.play();
      this.isPlaying = true;
    },

    goNext() {
      if (this.remain.length < 1) {
        this.backTitle();
        return;
      }
      this.isAnswerMpde = false;
      this.nextMode();
      audioElem.src = this.generateSoundUriPathById(
        this.$store.state.level + 1,
        this.getRandomQuestion()
      );

      audioElem.currentTime = 0;
      audioElem.play();
      this.isPlaying = true;
      this.cardClose();
    },

    cardOpen() {
      this.$refs.cardTarget.open(this.qnum);
    },
    cardClose() {
      this.$refs.cardTarget.close();
    },
    playMode() {
      anime({
        targets: ".players",
        translateY: [0, 60],
        opacity: [1, 0],
        // rotateY: [130, 0],
        duration: 500,
        easing: "easeOutExpo",
        delay: 200,
      });
      anime({
        targets: ".nextButton",
        translateY: [-60, 0],
        opacity: [0, 1],
        // rotateY: [130, 0],
        duration: 500,
        easing: "easeOutExpo",
        delay: 200,
      });
      this.play = "off";
      this.nextb = "";
    },

    nextMode() {
      anime({
        targets: ".players",
        translateY: [-60, 0],
        opacity: [0, 1],
        // rotateY: [130, 0],
        duration: 500,
        easing: "easeOutExpo",
        delay: 200,
      });
      anime({
        targets: ".nextButton",
        translateY: [0, 60],
        opacity: [1, 0],
        // rotateY: [130, 0],
        duration: 500,
        easing: "easeOutExpo",
        delay: 200,
      });
      this.play = "on";
      this.nextb = "off";
    },

    getRandomQuestion() {
      let a = this.remain.splice(
        Math.floor(Math.random() * this.remain.length),
        1
      );
      this.qnum = a[0];
      this.$store.state.qnum = a[0];
      return a;
    },
  },
  mounted() {
    this.init();

    anime({
      targets: "#gamecard",
      translateY: [60, 0],
      opacity: [0, 0],
      // rotateY: [130, 0],
      duration: 0,
      easing: "easeOutExpo",
      delay: 0,
    });
    anime({
      targets: ".nextButton",
      translateY: [-60, -60],
      opacity: [0, 0],
      // rotateY: [130, 0],
      duration: 0,
      easing: "easeOutExpo",
      delay: 0,
    });
    audioElem.addEventListener("loadedmetadata", () => {
      this.totalTime = audioElem.duration;
    });
    audioElem.addEventListener(
      "timeupdate",
      () => {
        this.pre = Math.floor((audioElem.currentTime / this.totalTime) * 100);
      },
      true
    );
    audioElem.addEventListener("ended", () => {
      this.isPlaying = false;
      if (!this.isAnswerMpde) return;

      this.cardOpen();
    });
  },
  created() {},
};
</script>

<style lang="scss">
#gamecard {
  border: 10px solid transparent;

  background: linear-gradient(rgb(255, 253, 181), rgb(255, 253, 181))
      padding-box,
    repeating-linear-gradient(
        -45deg,
        #f479b0 0,
        #f479b0 12.5%,
        rgb(255, 253, 181) 0,
        rgb(255, 253, 181) 25%,
        #62bcfc 0,
        #62bcfc 37.5%,
        rgb(255, 253, 181) 0,
        rgb(255, 253, 181) 50%
      )
      0 / 5em 5em;

  border-radius: 15px;
  width: 900px;
  height: 550px;
  @media all and (max-width: 900px) {
    width: 100%;
    height: calc(100vh - 70px);
    border: none;
    box-shadow: none;
    border-radius: 0;
  }

  .contentWrapper {
    height: 425px;
    .theCard {
      margin-top: 30px;
      @media all and (max-width: 900px) {
        margin-top: 110px;
      }
    }
    @media all and (max-width: 900px) {
      flex-direction: column-reverse;
      height: fit-content;
    }
    .cardTarget {
      width: 550px;
      height: 300px;
      @media all and (max-width: 900px) {
        width: 100%;
      }
    }

    .levelButtons {
      width: 250px;
      height: 380px;
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      flex-wrap: wrap;
      @media all and (max-width: 900px) {
        width: min(100%, 550px);
        justify-content: center;
        height: fit-content;
        flex-wrap: wrap !important;
        flex-flow: row;
        margin: 10px auto 0;
      }
    }
  }
  box-shadow: 0px 4px 15px -5px #6d6d6d;
  .inner {
    border-radius: 10px;
    width: 100%;
    height: 100%;

    @media all and (max-width: 900px) {
      width: 100%;
      margin: 0;
      border-radius: 0;
      height: calc(100vh - 70px);
    }
    background-image: repeating-linear-gradient(
      #fefcec 0px,
      #fefcec 24px,
      #d8d6c7 25px
    );

    .gameTitle {
      margin-top: 30px;
      width: 600px;
      max-width: calc(100% - 60px);
    }
  }

  .start {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
  .prog {
    margin: 20px auto;
    width: min(500px, 100% - 100px);
  }
  .progress {
    height: 2rem;
    border-radius: 15px;
  }
  .off {
    pointer-events: none;
  }
  .remain {
    font-family: "dmfont";
    position: absolute;
    letter-spacing: 0.3rem;
    top: 50px;
    right: 10px;
    font-size: 3rem;
    span {
      font-size: 4rem;
    }
    @media all and (max-width: 900px) {
      top: 40px;
      right: 40px;
      font-size: 2rem;
      span {
        font-size: 3rem;
      }
    }
  }
}
</style>
