<template>
  <div id="card">
    <div class="inner">
      <img src="../../assets/img/SMC_title.png" alt="" class="gameTitle" />

      <div class="contentWrapper">
        <div class="levelButtons">
          <cbutton
            message="Level 1"
            color="red"
            :setlevel="Number(0)"
            @flip="filpCard"
          ></cbutton>
          <cbutton
            message="Level 2"
            color="orange"
            :setlevel="Number(1)"
            @flip="filpCard"
          ></cbutton>
          <cbutton
            message="Level 3"
            color="yellow"
            :setlevel="Number(2)"
            @flip="filpCard"
          ></cbutton>
          <cbutton
            message="Level 4"
            color="green"
            :setlevel="Number(3)"
            @flip="filpCard"
          ></cbutton>
        </div>
        <card ref="cardTarget"> </card>
      </div>
      <button class="level" @click="ssc">START!</button>
    </div>
  </div>
</template>

<script>
import card from "../card/card.vue";
import cbutton from "../ui/coolButton.vue";

import anime from "animejs";

export default {
  name: "maincard",
  components: {
    card,
    cbutton,
  },
  methods: {
    emitter() {},
    filpCard(newColor) {
      this.$refs.cardTarget.flipCard(newColor);
    },
    ssc() {
      this.$emit("scc");
    },
    fadeIn() {
      anime({
        targets: "#card",
        translateY: [-60, 0],
        opacity: [0, 1],
        // rotateY: [130, 0],
        duration: 500,
        easing: "easeOutExpo",
        delay: 200,
      });
    },
    fadeOut() {
      anime({
        targets: "#card",
        translateY: [0, 60],
        opacity: [1, 0],
        // rotateY: [130, 0],
        duration: 500,
        easing: "easeOutExpo",
        delay: 200,
      });
    },
  },
  mounted() {
    anime({
      targets: "#card",
      translateY: [60, 0],
      opacity: [0, 1],
      // rotateY: [130, 0],
      duration: 1500,
      easing: "easeOutExpo",
      delay: 200,
    });
  },
};
</script>

<style lang="scss">
#card {
  border: 10px solid transparent;

  background: linear-gradient(rgb(255, 253, 181), rgb(255, 253, 181))
      padding-box,
    repeating-linear-gradient(
        -45deg,
        #f479b0 0,
        #f479b0 12.5%,
        rgb(255, 253, 181) 0,
        rgb(255, 253, 181) 25%,
        #62bcfc 0,
        #62bcfc 37.5%,
        rgb(255, 253, 181) 0,
        rgb(255, 253, 181) 50%
      )
      0 / 5em 5em;

  border-radius: 15px;
  width: 900px;
  height: 550px;
  @media all and (max-width: 900px) {
    width: 100%;
    height: calc(100vh - 70px);
    border: none;
    box-shadow: none;
    border-radius: 0;
  }

  .contentWrapper {
    display: flex;

    height: 400px;
    @media all and (max-width: 900px) {
      flex-direction: column-reverse;
      height: fit-content;
    }
    .cardTarget {
      width: 550px;
      height: 300px;
      @media all and (max-width: 900px) {
        width: 100%;
      }
    }

    .levelButtons {
      width: 250px;
      height: 380px;
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      flex-wrap: wrap;
      @media all and (max-width: 900px) {
        width: min(100%, 550px);
        justify-content: center;
        height: fit-content;
        flex-wrap: wrap !important;
        flex-flow: row;
        margin: 10px auto 0;
      }
    }
  }
  box-shadow: 0px 4px 15px -5px #6d6d6d;
  .inner {
    border-radius: 10px;
    width: 100%;
    height: 100%;

    @media all and (max-width: 900px) {
      width: 100%;
      margin: 0;
      border-radius: 0;
      height: calc(100vh - 70px);
    }
    background-image: repeating-linear-gradient(
      #fefcec 0px,
      #fefcec 24px,
      #d8d6c7 25px
    );

    .gameTitle {
      margin-top: 30px;
      width: 600px;
      max-width: calc(100% - 60px);
    }
  }
  .matome {
    position: relative;
  }

  .start {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }

  .level {
    position: absolute;
    transform: translateZ(500px);
    bottom: 20px;
    right: 170px;
    margin: auto;
    width: min(300px, 100% - 50px);
    color: black;
    font-size: 2rem;
    font-family: "dmfont";
    background: #89f3ff;
    border-radius: 2rem;
    padding: 0.8rem 1rem 0.1rem;
    border: black solid 2px;
    @media all and (max-width: 900px) {
      margin-top: 30px;
      position: relative;
      bottom: 0;
      right: 0;
    }
  }
  .level::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0;
    bottom: 0px;
    left: 0;
    background: transparent;
    border: #89f3ff00 solid 5px;
    border-radius: 6rem;
    animation: 1s ease-out 1s infinite move_eye;
  }
  @keyframes move_eye {
    0% {
      border: #89ffa6 solid 5px;
      top: 0px;
      right: 0;
      bottom: 0px;
      left: 0;
    }
    100% {
      border: #20d03a00 solid 15px;
      top: -20px;
      right: -20px;
      bottom: -20px;
      left: -20px;
    }
  }
}
</style>
